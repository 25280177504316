import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import PSIcon from './PSIcon';
import PSTitle from './PSTitle';

const StyledPSMessageResponse = styled.div`
  background: transparent linear-gradient(90deg, #ed6e00 0%, #2b0d61 100%) 0% 0% no-repeat
    padding-box;
  width: 300px;
  height: 500px;
  padding: 1rem 1.2rem 4rem 1.2rem;
  display: block;
  align-items: initial;
`;

const StyledPsHeaderIcon = styled.div`
  padding: 0;
  width: 100%;
  border: none;
  height: initial;
  display: block;
  align-items: initial;
  text-align: right;
  cursor: pointer;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const StyledPsBodyResponse = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
  .span-container {
    line-height: 17px;
  }
  label,
  span {
    font-family: 'BlissPro-Regular', sans-serif;
  }
`;
const PSMessageResponse = React.forwardRef(({ typeIconClose, className, title, icon, onClose, children , viewIconClose }, ref) => (
  <StyledPSMessageResponse className={className} ref={ref}>
    <StyledPsHeaderIcon onClick={onClose}>
    {viewIconClose && <PSIcon type={typeIconClose} />}
    </StyledPsHeaderIcon>
    <StyledPsBodyResponse>
      <div>
        {icon && <PSIcon type={icon} />}
        <PSTitle className="title text-white mt-1">{title}</PSTitle>
        <div className="span-container">
          <span className="text-white mt-1">{children}</span>
        </div>
      </div>
    </StyledPsBodyResponse>
  </StyledPSMessageResponse>
));

PSMessageResponse.displayName = 'PSMessageResponse';

PSMessageResponse.defaultProps = {
  title: '',
  typeIconClose : 'close',
  onClose: () => {},
  viewIconClose : true ,
};

PSMessageResponse.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
  typeIconClose:  PropTypes.string,
  viewIconClose : PropTypes.bool
};

export default PSMessageResponse;
